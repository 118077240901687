<template>
  <div class="content">
    <div class="content__title content__title--party content__title--start">
      <button
        @click="$router.go(-1)"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </button>
      <h1 class="title title--big title--theme">Добавление соглашения</h1>
    </div>
    <form class="form" @submit.prevent>
      <div class="form__content">
        <div class="form__part">
          <div class="form-group" :class="{'form-group--error': $v.form.type_of.$error}">
            <label>Состояние<sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Действующее</div>
                <input type="radio" name="is_staff" v-model.number="form.type_of" value="1" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Архив</div>
                <input type="radio" name="is_staff" v-model.number="form.type_of" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.type_of.$dirty && !$v.form.type_of.required">Обязательное поле</div>
          </div>
          <div class="form-group" :class="{'form-group--error': $v.form.type_of.$error}">
            <label>Тип соглашения<sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">С юридическим лицом</div>
                <input type="radio" name="is_entity" v-model="form.is_entity" :value="true" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Иное</div>
                <input type="radio" name="is_entity" v-model="form.is_entity" :value="false" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.is_entity.$dirty && !$v.form.is_entity.required">Обязательное поле</div>
          </div>
          <div v-if="form.is_entity" class="form-group">
            <label>Юридическое лицо<sup>*</sup></label>
            <v-select
              @input="setValues"
              v-model="org_detail"
              :filterable="false"
              :options="organization_options"
              @search="onOrgSearch"
              :get-option-label="getLabel"
              placeholder="Введите ИНН или название организации"
              class="select"
              :class="{ 'select--error': $v.form.entity.$error }"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите ИНН или название организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.inn }}) ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.entity.$dirty && !$v.form.entity.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="name">Название партнера</label>
            <div class="form-control">
              <input
                id="name"
                type="text"
                class="form-control__input"
                name="name"
                placeholder="Министерство образования"
                v-model.trim="form.name"
              />
            </div>
          </div>
          <div class="content__title">
            <h2 class="title">Для трехсторонних соглашений</h2>
          </div>
          <div class="form-group">
            <label>Дополнительное юридическое лицо</label>
            <v-select
              @input="setThirdValues"
              v-model="third_org_detail"
              :filterable="false"
              :options="organization_options"
              @search="onOrgSearch"
              :get-option-label="getLabel"
              placeholder="Введите ИНН или название организации"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите ИНН или название организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.inn }}) ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label for="third_name">Дополнительное название партнера</label>
            <div class="form-control">
              <input
                id="third_name"
                type="text"
                class="form-control__input"
                name="third_name"
                placeholder="Министерство образования"
                v-model.trim="form.third_name"
              />
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label for="date">Дата подписания соглашения<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date.$error }">
              <masked-input
                v-model="form.date"
                name="date"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date"
                autocomplete="off"
                @blur.native="$v.form.date.$touch"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.date.$dirty && !$v.form.date.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="date_end">Дата окончания действия соглашения</label>
            <div class="form-control">
              <masked-input
                v-model="form.date_end"
                name="date_end"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_end"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.form.file.$error }">
            <label>Скан-копия соглашения<sup>*</sup></label>
            <uploader :getFileHash="getFileHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile" />
            <div class="form-group__error" v-if="$v.form.file.$dirty && !$v.form.file.required">
              Обязательное поле
            </div>
          </div>
          <div v-if="userRole === 'admin'" class="form-group">
            <label>Статус<sup>*</sup></label>
            <v-select
              v-model="form.status"
              :reduce="status => status.id"
              :searchable="false"
              :clearable="false"
              :options="status_options"
              :get-option-label="getLabel"
              placeholder="Выберите статус"
              class="select"
              :class="{'select--error': $v.form.status.$error}"
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.status.$dirty && !$v.form.status.required">
              Обязательное поле
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button
          v-if="userRole !== 'admin'"
          @click="onCheckForm('draft')"
          class="button button--gray"
          type="button"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Сохранить черновик</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
        <button
          v-if="userRole !== 'admin'"
          @click="onCheckForm('review')"
          class="button"
          type="button"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Отправить на проверку</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
        <button
          v-if="userRole === 'admin'"
          @click="onCheckForm(form.status)"
          class="button"
          type="button"
          :class="{ 'preload': loading }"
          :disabled="$v.form.$error"
        >
          <span>Добавить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { debounce, omit } from 'lodash'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import Uploader from '@/components/Uploader'

export default {
  name: 'AgreementCreate',
  mixins: [ validationMixin ],
  components: {
    MaskedInput,
    Uploader,
  },
  validations: {
    form: {
      entity: {
        required: requiredIf(function(){
          return this.form.is_entity
        })
      },
      is_entity: { required },
      type_of: { required },
      date: { required },
      file: { required },
      status: {
        required: requiredIf(function(){
          return this.userRole === 'admin'
        })
      },
    },
  },
  data() {
    return {
      form: {
        type_of: 1,
        file: '',
        entity: '',
        is_entity: true,
      },
      file_url: `${process.env.VUE_APP_API_URL}files/`,
      org_detail: null,
      third_org_detail: null,
      loading: false,
      organization_options: [],
      status_options: [],
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    if (this.userRegion) this.form.region_org = this.userRegion
    if (this.userRole === 'admin') {
      this.$store.dispatch('status/GET_LIST')
        .then(response => {this.status_options = response.data.results})
    }
  },
  methods: {
    onCheckForm(saveType = '') {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (saveType) this.form.status = saveType
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      if (!this.form.date_end) {
        this.form = omit(this.form, ['date_end'])
      }
      if (!this.form.is_entity) {
        this.form.entity = ''
        this.form.entity_json = ''
      }
      this.$store.dispatch('agreement/POST_DATA', this.form)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'agreement' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Соглашение добавлено.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка добавления соглашения.'
          });
        });
    },
    setValues() {
      if (this.org_detail) {
        this.form.entity_json = this.org_detail
        this.form.entity = this.org_detail.name
      } else {
        this.form.entity_json = ''
        this.form.entity = ''
      }
      this.$v.form.entity.$touch()
    },
    setThirdValues() {
      if (this.third_org_detail) {
        this.form.third_entity_json = this.third_org_detail
        this.form.third_entity = this.third_org_detail.name
      } else {
        this.form.third_entity_json = ''
        this.form.third_entity = ''
      }
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onOrgSearch (search, loading) {
      loading(true);
      this.searchOrg(loading, search, this);
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_ORGANIZATION', { search }).then(response => {
        vm.organization_options = response.data;
        loading(false);
      });
    }, 350),
    getFileHash(value) {
      this.form.file = value
      this.$v.form.file.$touch()
    },
    onRemoveFile() {
      this.form.file = ''
      this.$v.form.file.$touch()
    },
  }
}
</script>
